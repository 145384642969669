/* eslint-disable */ 
import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="" style={{textAlign:"center",width:"100%",overflow:"hidden"}}>
        
    <h1 style={{marginTop:100, fontSize:50,fontWeight:100}}>Frontier Fund</h1>
    <div style={{marginTop:200,fontSize:30,fontWeight:100,width:"50%",textAlign:"left",marginLeft:"auto",marginRight:"auto",display:"block",overflow:"hidden"}}>
      
      Frontier Fund invests, acquires, and operates digital and physical properties.
      <br/>
      <br/>

      We combine frontier technology, with our leadership team with exceptional operating expertise to transform and accelerate businesses. 
      <br/>
      <br/>

       <br/>
       <br/>


       <div style={{width:"100%",textAlign:"center",display:"none"}}>
         <img src="/logo.png" style={{height:400}}/>
       </div>

    </div>
   </div>
  );
}

export default App;
